<template>
  <secured-area :operations="['List_Roles', 'View_Role', 'Create_Role', 'Edit_Role', 'Remove_Role', 'Add_User_To_Role', 'Remove_User_From_Role', 'View_Users_In_Roles', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role', 'View_Operations_For_Roles']">
    <h1>Rôles</h1>
    <roles-list
      :items = "roles"
      @view = "onViewItem"
      @edit = "onEditItem"
      @archive = "onArchiveItem"
      @unarchive = "onUnarchiveItem"
      @remove = "onRemoveItem"
    />
  </secured-area>
</template>

<script>
import RolesList  from '@/components/Security/Roles/List.vue';
import axios from 'axios';

export default {
  components: { RolesList },
  data(){
    return {
      roles: [],
    }
  },
  methods:{
    onViewItem(item){
      console.log('onViewItem', item);
    },
    onEditItem(item){
      console.log('onEditItem', item);
      this.$showLoader()
        .then(() => {
          this.$router.push({ name: 'SecurityRoleEdit', params: { id: item}});
        });
    },
    onArchiveItem(item){
      console.log('onArchiveItem', item);
    },
    onUnarchiveItem(item){
      console.log('onUnarchiveItem', item);
    },
    onRemoveItem(item){
      console.log('onDeleteItem', item);
    },
    refresh(){
      var url = this.getUrl('/api/Security/getRoles.php');

      axios.get(url)
        .then((response) => {
          this.roles = response.data.roles;
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
  mounted(){
    this.$hideLoader();
    this.refresh();
  }
}
</script>
