import { render, staticRenderFns } from "./UnarchiveIcon.vue?vue&type=template&id=0fb22d85&"
import script from "./UnarchiveIcon.vue?vue&type=script&lang=js&"
export * from "./UnarchiveIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports