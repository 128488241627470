<template>
  <secured-area :operations="['List_Roles', 'View_Role', 'Edit_Role', 'Remove_Role', 'Add_User_To_Role', 'Remove_User_From_Role', 'View_Users_In_Roles', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role', 'View_Operations_For_Roles']">
    <fieldset class="fieldset" v-show="filtersEnabled">
      <legend>Filtre(s)</legend>
    </fieldset>
    <b-table small hover :items="itemsList" :fields="columns" class="thisTable" responsive>

        <template #cell(selector)="data">
            <b-button size="sm" :disabled="data.item.cannotBeSelected" @click="select(data.item)">Select.</b-button>
        </template>

        <template #cell(name)="data">
          <span class="name">{{ data.item.name}}</span>
        </template>
        <template #cell(dislayName)="data">
          <span class="display-name">{{ data.item.displayName}}</span>
        </template>
        <template #cell(description)="data">
          <span class="description">{{ data.item.description }}</span>
        </template>
        <template #cell(usersCount)="data">
          <span class="usersCount">{{ data.item.usersCount }}</span>
        </template>
        <template #cell(active)="data">
          <b-checkbox v-model="data.item.active" :disabled="true" />
        </template>

        <template #cell(commands)="data">
            <row-commands
              :rowItem = "data.item.name"

              :viewBtnVisible="canView || CanViewUsersInRole || canViewOperationsForRole"
              @viewBtnClick="view"

              :editBtnVisible="!viewOnlyMode && (canEdit || canGrantOperation || canRevokeOperation || canAddUserToRole || CanRemoveUserFromRole)"
              @editBtnClick="edit"

              :archiveBtnVisible="!viewOnlyMode && canRemove && data.item.isActive"
              @archiveBtnClick="archive"

              :unarchiveBtnVisible="!viewOnlyMode && canRemove && !data.item.isActive"
              @unarchiiveBtnClick="unarchive"

              :deleteBtnVisible="!viewOnlyMode && canRemove && !data.item.isActive"
              :deleteBtnDisabled="data.item.usersCount > 0 || data.item.operationsCount > 0"
              @deleteBtnClick="remove"
            />
        </template>
    </b-table>

    <b-modal
      v-model="showDeletionConfirmationDialog"
      title="Confirmation de suppression"
      @ok="remove(roleToDelete)"
      @cancel="roleToDelete=null"
      ok-variant="warning"
      ok-title="Oui, supprimer"
      cancel-variant="secondary"
      cancel-title="Non"
    >
      <p>Etes-vous sûr de vouloir supprimer ce rôle ?</p>
    </b-modal>
  </secured-area>
</template>

<script>
import RowCommands from "@/components/Lists/RowCommands.vue";
import { mapActions } from "vuex";

export default {
  components: { RowCommands },
  props: {
    viewOnlyMode: { type: Boolean, default: false },
    items: { type: Array },
    nameColumnVisible: { type: Boolean, default: true },
    displayNameColumnVisible: { type: Boolean, default: true },
    descriptionColumnVisible: { type: Boolean, default: true },
    usersCountColumnVisible: { type: Boolean, default: true },
    isActiveColumnVisible: { type: Boolean, default: true },
    filtersEnabled: { type: Boolean, default: false },
  },
  data(){
    return {
      showDeletionConfirmationDialog: false,
      roleToDelete: null,
      canView: false,
      canEdit: false,
      canArchive: false,
      canUnarchive: false,
      canGrantOperation: false,
      canRevokeOperation: false,
      canViewOperationsForRole: false,
      canAddUserToRole: false,
      CanRemoveUserFromRole: false,
      CanViewUsersInRole: false,
      canRemove: false,
    }
  },
  computed:{
    columns(){
      var ret = [];

      if(this.nameColumnVisible)
        //ret.push({key: 'selector', sortable: false, label: '', class:"selector", stickyColumn: true});
        ret.push({key: 'name', sortable: true, label: 'Nom (code)', stickyColumn: true});

      if(this.displayNameColumnVisible)
        ret.push({ key: 'displayName', sortable: true, label: 'Libellé'});

      if(this.descriptionColumnVisible)
        ret.push({ key: 'description', sortable: false, label: 'Description'});

      if(this.usersCountColumnVisible)
        ret.push({ key: 'usersCount', sortable: true, label: 'Utilisateurs'});

      if(this.isActiveColumnVisible)
        ret.push({ key: 'isActive', sortable: true, label:'Actif'});

      ret.push({ key: 'commands', sortable: false, label: ''});

      return ret;
    },
  },
  methods: {
    ...mapActions('auth', ['userCan']),
    view(item){
      this.$emit('view', item);
    },
    edit(item){
      this.$emit('edit', item);
    },
    archive(item){
      this.$emit('archive', item);
    },
    unarchive(item){
      this.$emit('unarchive', item);
    },
    confirmDeletion(item){
      this.roleToDelete = item;
      this.showDeletionConfirmationDialog = true;
    },
    remove(item){
      this.$emit('remove', item);
    },
    async itemsList(){
      this.canView = await this.userCan('View_Role');
      this.canEdit = await this.userCan('Edit_Role');
      this.canArchive = await this.userCan('Archive_Role');
      this.canUnarchive = await this.userCan('Unarchive_Role');
      this.canGrantOperation = await this.userCan('Grant_Operation_To_Role');
      this.canRevokeOperation = await this.userCan('Revoke_Operation_From_Role');
      this.canViewOperationsForRole = await this.userCan('View_Operations_For_Roles');
      this.canAddUserToRole = await this.userCan('Add_User_To_Role');
      this.CanRemoveUserFromRole = await this.userCan('Remove_User_From_Role');
      this.CanViewUsersInRole = await this.userCan('View_Users_In_Roles');
      this.canRemove = await this.userCan('Remove_Role');


      var ret = [];

      if(this.items != null){
        ret = this.items.map(element => {
          return {
            id: element.id,
            name: element.name,
            displayName: element.displayName,
            description: element.description,
            isActive: (element.isActive == 1),
            isDeletable: !element.isInRole,
            usersCount: element.usersCount,
            cannotBeDeleted: true,
          }
        });
      }

      return ret;
    }
  }
}
</script>
