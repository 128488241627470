<template>
  <div>
    <b-button size="sm"
      v-show="viewBtnVisible"
      :disabled="viewBtnDisabled"
      :variant="viewBtnVariant"
      @click="$emit('view')"
    >
      <view-icon />
    </b-button>

    <b-button size="sm"
      v-show="editBtnVisible"
      :disabled="editBtnDisabled"
      :variant="editBtnVariant"
      @click="btnClicked('edit')"
    >
      <edit-icon />
    </b-button>

    <b-button size="sm"
      v-show="archiveBtnVisible"
      :disabled="archiveBtnDisabled"
      :variant="archiveBtnVariant"
      @click="btnClicked('archive')"
    >
      <archive-icon />
    </b-button>

    <b-button size="sm"
      v-show="unarchiveBtnVisible"
      :disabled="unarchiveBtnDisabled"
      :variant="unarchiveBtnVariant"
      @click="btnClicked('unarchive')"
    >
      <unarchive-icon />
    </b-button>

    <b-button size="sm"
      v-show="deleteBtnVisible"
      :disabled="deleteBtnDisabled"
      :variant="deleteBtnVariant"
      @click="btnClicked('delete')"
    >
        <delete-icon/>
    </b-button>
  </div>
</template>

<script>
import ViewIcon from "@/components/Icons/ViewIcon";
import EditIcon from "@/components/Icons/EditIcon";
import ArchiveIcon from "@/components/Icons/ArchiveIcon";
import UnarchiveIcon from "@/components/Icons/UnarchiveIcon";
import DeleteIcon from "@/components/Icons/DeleteIcon"

export default {
  components: { ViewIcon, EditIcon, ArchiveIcon, UnarchiveIcon, DeleteIcon },
  model:{ prop: 'rowItem'},
  props:{
    rowItem: { type:Object, default: null},
    viewBtnVisible: { type:Boolean, default: false},
    viewBtnDisabled: { type:Boolean, default: false},
    viewBtnVariant: { type:String, default:'success'},
    editBtnVisible: { type:Boolean, default: false},
    editBtnDisabled: { type:Boolean, default: false},
    editBtnVariant: { type:String, default:'secondary'},
    archiveBtnVisible: { type:Boolean, default: false},
    archiveBtnDisabled: { type:Boolean, default: false},
    archiveBtnVariant: { type:String, default:'secondary'},
    unarchiveBtnVisible: { type:Boolean, default: false},
    unarchiveBtnDisabled: { type:Boolean, default: false},
    unarchiveBtnVariant: { type:String, default:'secondary'},
    deleteBtnVisible: { type:Boolean, default: false},
    deleteBtnDisabled: { type:Boolean, default: false},
    deleteBtnVariant: { type:String, default:'danger'},
  },
  methods:{
    btnClicked(eventName){
      var event = eventName + 'BtnClick';
      console.log('btnClicked', event);
      this.$emit(event, this.rowItem);
    }
  }
}
</script>
